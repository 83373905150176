<template>
  <div>
    <a-card>
      <!--            <div class="table-operator">-->
      <!--                <a-button type="primary" icon="plus" @click="addCity">新建</a-button>-->
      <!--            </div>-->
      <div class="table-operator">
          <a-popconfirm placement="bottomLeft" title="确定账单切换到CSS？" @confirm="switchToCSS">
            <a-button style="margin-right: 16px" v-if="this.isCSS === false">账单切换CSS</a-button>
          </a-popconfirm>
          <a-popconfirm placement="bottomLeft" title="确定关闭账单切换到CSS？" @confirm="switchToFMS" >
            <a-button style="margin-right: 16px" v-if="this.isCSS === true">账单切换FMS</a-button>
          </a-popconfirm>
      </div>
      <div class="table-operator">
        <div class="detailStyle" style="margin-left: 8px">
          <label>{{ this.staffList }}</label>
        </div>
      </div>
    </a-card>
  </div>
</template>

<script>
  
import axios from "axios";
import moment from "moment";
import Bus from "@/bus";
import { defaultPagination } from "@/utils/pagination";
export default {
  name: "cssConfigure",
  data() {
    return {
      data: [],
      staffList : "",
      visible: false,
      confirmLoading: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      },
      isCSS: false,
      cityConfigureEdit: false
    };
  },
  created() {
    this.init();
    Bus.$on("getTarget", target => {
      this.init();
    });
  },
  methods: {
    moment,
    init() {
      axios({
        url:   this.$baseUrl + "bill/getUseCss",
        method: "GET",
        params: {}
      }).then(res => {
        this.data = res.data.obj;
        if (res.data.obj) {
          this.staffList = res.data.obj.staffList;
          this.isCSS = res.data.obj.useCss;
        }
        this.confirmLoading = false;
      });
    },
    switchToCSS() {
      axios({
        url:   this.$baseUrl + "bill/useCss?useCss=true",
        method: "POST",
        withCredentials: true,
      }).then(res => {
        if (res.data.success) {
          this.$message.success("成功！");
        } else {
          this.$message.error(`${res.data.returnMsg}`);
        }
        this.init();
      });
    },
    switchToFMS() {
      axios({
        url:   this.$baseUrl + "bill/useCss?useCss=false",
        method: "POST",
        withCredentials: true,
      }).then(res => {
        if (res.data.success) {
          this.$message.success("成功！");
        } else {
          this.$message.error(`${res.data.returnMsg}`);
        }
        this.init();
      });
    },
  }
};
</script>

<style scoped>
.table-operator {
  margin-bottom: 24px;
}
.backName .ant-input {
  border-radius: 0 !important;
  width: 445px;
  height: 200px;
}
</style>
